<template>
  <router-link
    :to="{
      name: 'couponEdit',
      params: { id: coupon.id },
    }"
  >
    <div class="relative w-full border border-primary transition-shadow hover:shadow-md">
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[20%]">
          <p class="mb-1 font-medium">{{ t('code') }}</p>
          <p>{{ coupon.code }}</p>
        </div>

        <div class="basis-[20%]">
          <p class="mb-1 font-medium">{{ t('remainingValue') }}</p>
          <p>{{ formatCurrency(coupon.remainingValue) }}</p>
        </div>

        <div class="basis-[35%]">
          <p class="mb-1 font-medium">{{ t('customer') }}</p>
          <p>
            {{
              coupon.customerData
                ? `${coupon.customerData.firstName} ${coupon.customerData.lastName}`
                : '-'
            }}
          </p>
        </div>

        <div class="basis-[15%]">
          <span
            :class="{
              'px-2 py-1 text-sm': true,
              'bg-green-100 text-green-800': coupon.isActive,
              'bg-red-100 text-red-800': !coupon.isActive,
            }"
          >
            {{ coupon.isActive ? t('active') : t('inactive') }}
          </span>
        </div>

        <div class="flex basis-[10%] justify-end">
          <DropdownDotted :buttons="buttons" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { Coupon } from '@/entities/coupons/coupon.entity';
import { formatCurrency } from '@/utils/format-numbers';
import { couponsService } from '@/api/coupons.service';
import DropdownDotted from '@/components/DropdownDotted.vue';
import { computed } from 'vue';

const props = defineProps<{
  coupon: Coupon;
}>();

const { t } = useI18n();

const downloadPdf = async (couponId: string) => {
  const blob = await couponsService.downloadPdf(couponId);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `coupon-${couponId}.pdf`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const buttons = computed(() => [
  {
    title: t('downloadPdf'),
    onClick: () => downloadPdf(props.coupon.id),
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "code": "Code",
    "remainingValue": "Remaining Value",
    "customer": "Customer",
    "active": "Active",
    "inactive": "Inactive",
    "editCoupon": "Edit coupon",
    "downloadPdf": "Download PDF"
  },
  "de": {
    "code": "Code",
    "remainingValue": "Restbetrag",
    "customer": "Kunde",
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "editCoupon": "Gutschein bearbeiten",
    "downloadPdf": "PDF herunterladen"
  }
}
</i18n>
