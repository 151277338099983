export enum PaymentType {
  VISA = 'VISA',
  MASTCARD = 'MASTERCARD',
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  DINERS = 'DINERS',
  MAESTRO = 'MAESTRO',
  JCB = 'JCB',
  CUP = 'CUP',
  ATM_CARD = 'ATM_CARD',
  TRANSFER = 'TRANSFER',
  PAYPAL = 'PAYPAL',
  CASH = 'CASH',
  VOUCHER = 'VOUCHER',
  DEPOSIT = 'DEPOSIT',
  COUPON = 'COUPON',
  OTHER = 'OTHER',
}
