<template>
  <form @submit.prevent="onSubmit" class="space-y-6">
    <div class="space-y-6">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <InputField
            name="remainingValue"
            :label="t('remainingValue')"
            type="number"
            :min="0"
            :max-fraction-digits="2"
            required
            class="w-full"
          />
        </div>
        <div class="flex items-center">
          <CheckboxField name="isActive" :label="t('active')" class="mt-2" />
        </div>
      </div>

      <div v-if="initialData?.orderInvoice" class="mt-4 rounded-lg bg-gray-50 p-4">
        <div class="flex items-center justify-between">
          <div>
            <div class="text-sm text-gray-600">{{ t('orderInvoice') }}</div>
            <div class="text-lg font-medium">{{ initialData.orderInvoice?.invoiceNumber }}</div>
          </div>
          <RouterLink
            :to="{ name: 'invoice-edit', params: { id: initialData.orderInvoice?.id } }"
            class="hover:text-primary-dark text-primary"
          >
            {{ t('viewInvoice') }}
          </RouterLink>
        </div>
      </div>
      <Divider />
      <CustomerDataForm name="customerData" />
    </div>

    <Divider />

    <slot name="actions">
      <div class="flex justify-end">
        <CVButton type="submit" :loading="isSubmitting">
          {{ submitLabel }}
        </CVButton>
      </div>
    </slot>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import type { Coupon } from '@/entities/coupons/coupon.entity';
import { useI18n } from 'vue-i18n';
import InputField from '@/components/InputField.vue';
import CheckboxField from '@/components/CheckboxField.vue';
import CVButton from '@/components/buttons/CVButton.vue';
import CustomerDataForm from '@/components/booking-forms/customer/CustomerDataForm.vue';
import Divider from '@/components/Divider.vue';
import { RouterLink } from 'vue-router';
import { customerOptionalSchema } from '@/validation/customer.schema';
import * as yup from 'yup';

const props = defineProps<{
  initialData?: Partial<Coupon>;
  submitLabel?: string;
}>();

const emit = defineEmits<{
  (e: 'submit', data: Partial<Coupon>): void;
}>();

const { t } = useI18n();

const validationSchema = yup.object({
  remainingValue: yup.number().required().min(0),
  isActive: yup.boolean().required(),
  customerData: yup.object(customerOptionalSchema(0, new Date())).optional(),
});

const { handleSubmit, isSubmitting } = useForm({
  validationSchema,
  initialValues: {
    remainingValue: props.initialData?.remainingValue ?? 0,
    isActive: props.initialData?.isActive ?? true,
    customerData: props.initialData?.customerData,
  },
});

const onSubmit = handleSubmit((values) => {
  emit('submit', values);
});
</script>

<i18n lang="json">
{
  "en": {
    "remainingValue": "Remaining Value",
    "active": "Active",
    "customerData": "Customer Data",
    "firstName": "First Name",
    "lastName": "Last Name",
    "phone": "Phone",
    "company": "Company",
    "street": "Street",
    "city": "City",
    "zip": "ZIP",
    "country": "Country",
    "orderInvoice": "Order Invoice",
    "viewInvoice": "View Invoice"
  },
  "de": {
    "remainingValue": "Verbleibender Wert",
    "active": "Aktiv",
    "customerData": "Kundendaten",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "phone": "Telefon",
    "company": "Firma",
    "street": "Straße",
    "city": "Stadt",
    "zip": "PLZ",
    "country": "Land",
    "orderInvoice": "Bestellungsrechnung",
    "viewInvoice": "Rechnung anzeigen"
  }
}
</i18n>
