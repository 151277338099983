<template>
  <HeaderBar />

  <section class="carvia-container flex flex-col justify-center">
    <div class="mb-4 flex justify-between gap-4">
      <Input v-model="search" :placeholder="t('search')" class="w-full max-w-sm" />
      <CVButton :to="{ name: 'couponCreate' }">{{ t('createCoupon') }}</CVButton>
    </div>

    <div>
      <div v-if="flattenedCoupons.length > 0" class="flex flex-col space-y-2">
        <div v-for="coupon in flattenedCoupons" :key="coupon.id">
          <CouponListItem :coupon="coupon" />
        </div>
      </div>

      <EntityListExceptions
        :status="couponsStatus"
        :error="couponsError"
        :items-count="flattenedCoupons.length"
        :no-items-text="t('noCouponsFound')"
      />

      <div v-if="hasNextPage" class="flex justify-center pt-8">
        <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
          {{ t('loadMore') }}
        </CVButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import CVButton from '@/components/buttons/CVButton.vue';
import { useI18n } from 'vue-i18n';
import Input from '@/components/Input.vue';
import { debounce } from 'lodash';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import CouponListItem from '../components/CouponListItem.vue';
import { useUiStore } from '@/stores/ui.store';
import { useCouponsWithQuery } from '@/queries/use-coupons';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { toValue } from '@vueuse/core';

const { t } = useI18n();
const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('coupons'));
});

const search = ref('');
const searchQuery = ref('');

const refreshSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);

watch(search, () => {
  refreshSearchQuery();
});

const {
  data: coupons,
  status: couponsStatus,
  error: couponsError,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
} = useCouponsWithQuery(
  computed(() => ({
    search: toValue(searchQuery),
  })),
);

const flattenedCoupons = useFlattenPaginatedData(coupons);
</script>

<i18n lang="json">
{
  "en": {
    "coupons": "Coupons",
    "createCoupon": "+ New Coupon",
    "search": "Search",
    "noCouponsFound": "No coupons found",
    "loadMore": "Load More"
  },
  "de": {
    "coupons": "Gutscheine",
    "createCoupon": "+ Neuer Gutschein",
    "search": "Suche",
    "noCouponsFound": "Keine Gutscheine gefunden",
    "loadMore": "Mehr laden"
  }
}
</i18n>
