import { couponsService, type FindAllCouponsQueryDto } from '@/api/coupons.service';
import type { CreateCouponDto, UpdateCouponDto } from '@/api/coupons.service';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { toRef, toValue } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'coupons';

export const useCoupon = (id: MaybeRefOrGetter<string | undefined>) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    enabled: () => !!toValue(id),
    queryFn: () => couponsService.getOne(toRef(id).value!),
  });
};

export const useCouponsWithQuery = (
  query: MaybeRefOrGetter<FindAllCouponsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      couponsService.getAllWithQuery({
        page: pageParam,
        ...toValue(query),
      }),
    enabled: toRef(enabled),
    getNextPageParam: (lastPage) => lastPage?.meta?.nextPage ?? undefined,
    initialPageParam: 1,
  });
};

export const useUpdateCoupon = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: { id: string; update: UpdateCouponDto }) =>
      couponsService.update(params.id, params.update),
    onMutate: () =>
      ({
        successMessage: 'Coupon updated successfully',
        invalidateQueryKeys: [QUERY_KEY],
      }) satisfies MutationDefaultsContext,
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      onSuccess?.();
    },
  });
};

export const useDeleteCoupon = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => couponsService.remove(id),
    onMutate: () =>
      ({
        successMessage: 'Coupon deleted successfully',
        invalidateQueryKeys: [QUERY_KEY],
      }) satisfies MutationDefaultsContext,
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(null, null, context);
      onSuccess?.();
    },
  });
};

export const useCreateCoupon = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (coupon: CreateCouponDto) => couponsService.create(coupon),
    onMutate: () =>
      ({
        successMessage: 'Coupon created successfully',
        invalidateQueryKeys: [QUERY_KEY],
      }) satisfies MutationDefaultsContext,
  });
};
